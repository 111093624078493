import { render, staticRenderFns } from "./FlightCardIcons.vue?vue&type=template&id=3c84fe15&scoped=true"
import script from "./FlightCardIcons.vue?vue&type=script&lang=js"
export * from "./FlightCardIcons.vue?vue&type=script&lang=js"
import style0 from "./FlightCardIcons.vue?vue&type=style&index=0&id=3c84fe15&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c84fe15",
  null
  
)

export default component.exports