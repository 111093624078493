<template>
  <div class="flight-card-icons">
    <FlightStopIndicator :color="display.color" />
    <p style="margin-left: 0.15rem">{{ display.text }}</p>
    <IconClock style="margin-left: 0.5rem" />
    <p style="margin-left: 0.4rem">{{ display.duration }}</p>
  </div>
</template>

<script>
import { date } from '../../../other/utils/helpers/date'
import IconClock from '../../icons/common/IconClock.vue'
import FlightStopIndicator from './FlightStopIndicator.vue'

const formatDuration = date.prettyDuration('-dd -hh -minm')

export default {
  name: 'FlightCardIcons',
  components: { IconClock, FlightStopIndicator },
  props: {
    flightDirection: Object,
  },
  computed: {
    display() {
      const { stops, duration } = this.flightDirection
      return {
        duration: formatDuration(duration),
        color: stops ? 'red' : 'green',
        text:
          stops === 0
            ? 'Direct'
            : stops === 1
            ? `${stops} stop`
            : `${stops} stops`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.flight-card-icons {
  display: flex;
  font-size: 13px;
  font-family: DDin;
  align-items: center;
  line-height: 1;
}
</style>
