<template>
  <div class="fsi-wrap" :data-color="color">
    <div class="fsi-dot"></div>
    <div class="fsi-animated-dot"></div>
  </div>
</template>

<script>
export default {
  name: 'FlightStopIndicator',
  props: {
    color: { type: String, default: 'green' },
  },
}
</script>

<style scoped lang="scss">
.fsi-wrap {
  &[data-color='green'] {
    --color: #3ee162;
  }
  &[data-color='red'] {
    --color: #f42f04;
  }
  --size: 26px;
  --pulse-min-size: 40%;
  --pulse-max-size: 55%;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & > * {
    border-radius: 100%;
    position: absolute;
  }
}
.fsi-dot {
  height: var(--pulse-min-size);
  width: var(--pulse-min-size);
  background: var(--color);
  animation: pulse 3s infinite ease;
  box-shadow: 0 0 2px #0004;
}
.fsi-animated-dot {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  border: 1px solid var(--color);
  z-index: 1;
  animation: spread 1.5s infinite alternate ease;
}

@keyframes spread {
  0% {
    opacity: 1;
    width: calc(var(--pulse-min-size) - 2px);
    height: calc(var(--pulse-min-size) - 2px);
  }
  100% {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
@keyframes pulse {
  0% {
    width: var(--pulse-min-size);
    height: var(--pulse-min-size);
  }
  50% {
    width: var(--pulse-max-size);
    height: var(--pulse-max-size);
  }
  100% {
    width: var(--pulse-min-size);
    height: var(--pulse-min-size);
  }
}
</style>
